<template>
  <div>
    <a-form :ref="formRef" scrollToFirstError>
      <div class="create-candidate-container">
        <div class="create-candidate-header-text">Create New Candidate</div>
        <div class="create-candidate-basic-details">
          <div class="basic-candidate-info-form">
            <div class="form-sub-section">
              <div class="add-candidate-form-elements left-form-elements">
                <span class="input-label">First Name</span
                ><span class="required-input">*</span>
                <a-form-item v-bind="validateInfos.firstName">
                  <a-input
                    class="form-input"
                    placeholder="Enter"
                    v-model:value="modelRef.firstName"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-candidate-form-elements">
                <span class="input-label">Last Name</span
                ><span class="required-input">*</span>
                <a-form-item v-bind="validateInfos.lastName">
                  <a-input
                    class="form-input"
                    placeholder="Enter"
                    v-model:value="modelRef.lastName"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-candidate-form-elements left-form-elements">
                <span class="input-label">Resume Type</span
                ><span class="required-input">*</span>
                <a-form-item v-bind="validateInfos.resumeType">
                  <a-select
                    class="select-input"
                    :options="resume_type_options"
                    v-model:value="modelRef.resumeType"
                    placeholder="Select"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="add-candidate-form-elements">
                <span class="input-label">Resume ID</span
                ><span class="required-input">*</span>
                <a-form-item v-bind="validateInfos.resumeId">
                  <a-input
                    class="form-input"
                    placeholder="Autogenerates"
                    v-model:value="modelRef.resumeId"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-candidate-form-elements left-form-elements">
                <span class="input-label">Mobile Number</span>
                <a-form-item>
                  <a-input
                    class="form-input"
                    placeholder="Enter"
                    v-model:value="modelRef.mobile"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-candidate-form-elements">
                <span class="input-label">Email ID</span>
                <a-form-item v-bind="validateInfos.email">
                  <a-input
                    class="form-input"
                    placeholder="Enter"
                    v-model:value="modelRef.email"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-candidate-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">LinkedIn Profile</span>
                  <a-input
                    class="form-input"
                    placeholder="Enter"
                    v-model:value="modelRef.socialAccounts.linkedIn.link"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-candidate-form-elements">
                <a-form-item>
                  <span class="input-label">Gender</span>
                  <a-select
                    class="select-input"
                    :options="genderOptions"
                    v-model:value="modelRef.gender"
                    placeholder="Select"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="create-candidate-add-work-experience">
          <div class="title">Work Experience</div>
          <div class="form-sub-section">
            <div class="add-candidate-horizontal-elements">
              <MinusOutlined @click="workremoveData" class="icon-style" />
              <a-form-item>
                <a-input
                  class="form-input"
                  placeholder="Company"
                  style="width: 95%"
                  v-model:value="experiences.company"
                ></a-input>
              </a-form-item>
              <a-form-item>
                <a-input
                  class="form-input"
                  placeholder="Designation"
                  style="width: 95%"
                  v-model:value="experiences.designation"
                ></a-input>
              </a-form-item>
              <a-form-item>
                <a-input
                  class="form-input"
                  placeholder="Reporting To"
                  style="width: 95%"
                  v-model:value="experiences.reportingTo"
                ></a-input>
              </a-form-item>
              <a-form-item>
                <a-date-picker
                  placeholder="From Date"
                  class="date-input"
                  style="width: 95%; height: 43px"
                  v-model:value="experiences.from"
                ></a-date-picker>
              </a-form-item>
              <a-form-item>
                <a-date-picker
                  placeholder="To Date"
                  class="date-input"
                  style="width: 95%; height: 43px"
                  v-model:value="experiences.to"
                ></a-date-picker>
              </a-form-item>
              <a-form-item>
                <a-input
                  class="form-input"
                  placeholder="CTC"
                  style="width: 100%"
                  v-model:value="experiences.ctc"
                ></a-input>
              </a-form-item>
              <PlusOutlined @click="workdatasubmit" class="icon-style" />
            </div>
          </div>
          <div class="added-details-list">
            <a-table
              :columns="workExperienceTableColumns"
              :data-source="experiences.workdetailsDataList"
              :pagination="false"
              :showHeader="false"
            >
              <template #company="{ text }">
                {{ text !== "" ? text : "-" }}
              </template>
              <template #designation="{ text }">
                {{ text !== "" ? text : "-" }}
              </template>
              <template #reportingTo="{ text }">
                {{ text !== "" ? text : "-" }}
              </template>
              <template #from="{ text }">
                {{ text !== "" ? text : "-" }}
              </template>
              <template #to="{ text }">
                {{ text !== "" ? text : "-" }}
              </template>
              <template #ctc="{ text }">
                {{ text !== "" ? text : "-" }}
              </template>
              <template #delete="{ record }">
                <a-popconfirm
                  title="Are you sure delete you want to delete?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="confirmDelete(record.key)"
                  @cancel="cancel"
                >
                  <DeleteOutlined style="cursor: pointer" />
                </a-popconfirm>
              </template>
            </a-table>
          </div>
        </div>
        <div class="create-candidate-add-education-details">
          <div class="title">Education Details</div>
          <div class="form-sub-section">
            <div
              style="justify-content: start; width: 100%"
              class="add-candidate-horizontal-elements"
            >
              <MinusOutlined class="icon-style" @click="educationremoveData" />
              <a-form-item>
                <a-select
                  show-search
                  placeholder="Degree"
                  class="select-input"
                  style="width: 100%; margin-right: 12rem"
                  labelInValue
                  v-model:value="qualifications.degreeId"
                  :filter-option="false"
                  :options="education_degrees_options"
                  @search="getEducationDegree"
                >
                </a-select>
              </a-form-item>
              <a-form-item>
                <a-select
                  show-search
                  class="select-input"
                  placeholder="University"
                  style="width: 100%; margin-right: 12rem"
                  labelInValue
                  v-model:value="qualifications.instituteId"
                  :filter-option="false"
                  :options="university_options"
                  @search="getEducationinstitute"
                >
                </a-select>
              </a-form-item>
              <a-form-item>
                <a-select
                  class="select-input"
                  placeholder="Level"
                  style="width: 100%; margin-right: 12rem"
                  :options="education_levels_options"
                  v-model:value="qualifications.levelId"
                  labelInValue
                >
                </a-select>
              </a-form-item>
              <a-form-item>
                <a-select
                  :options="education_speciality_options"
                  class="select-input"
                  placeholder="Speciality"
                  v-model:value="qualifications.speciality"
                  style="width: 100%; margin-right: 12rem"
                  labelInValue
                >
                </a-select>
              </a-form-item>
              <a-form-item>
                <a-input
                  class="form-input"
                  placeholder="Year"
                  style="width: 100%"
                  v-model:value="qualifications.year"
                ></a-input>
              </a-form-item>
              <PlusOutlined class="icon-style" @click="educationdatasubmit" />
            </div>
          </div>
          <div class="added-details-list">
            <a-table
              :columns="educationDetailsColumns"
              :data-source="qualifications.educationDetailsDataList"
              :pagination="false"
              :showHeader="false"
            >
              <template #degreeId="{ text }">
                {{ text !== "" ? text : "-" }}
              </template>
              <template #instituteId="{ text }">
                {{ text !== "" ? text : "-" }}
              </template>
              <template #levelId="{ text }">
                {{ text !== "" ? text : "-" }}
              </template>
              <template #speciality="{ text }">
                {{ text !== "" ? text : "-" }}
              </template>
              <template #year="{ text }">
                {{ text !== "" ? text : "-" }}
              </template>
              <template #educationDelete="{ record }">
                <a-popconfirm
                  title="Are you sure delete you want to delete?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="confirmEducationDelete(record.key)"
                  @cancel="cancel"
                >
                  <DeleteOutlined style="cursor: pointer" />
                </a-popconfirm>
              </template>
            </a-table>
          </div>
        </div>
        <div class="create-candidate-taggings">
          <div class="title">Tagging</div>
          <div class="tag-selection-section">
            <a-form-item
              v-bind="validateInfos.verticals"
              style="margin-bottom: 0px"
            >
              <a-select
                v-model:value="modelRef.verticals"
                class="select-input"
                mode="tags"
                :filter-option="false"
                placeholder="Search and Select Vertical*"
                style="margin-bottom: 0.5rem"
                :options="vertical_tags_options"
                @search="fetchVerticalData"
              >
              </a-select>
            </a-form-item>
            <a-form-item
              v-bind="validateInfos.practiceAreas"
              style="margin-bottom: 0px"
            >
              <a-select
                v-model:value="modelRef.practiceAreas"
                class="select-input"
                mode="tags"
                placeholder="Search and Select Practice Area*"
                style="margin-bottom: 0.5rem"
                :filter-option="false"
                :options="practice_area_tags_options"
                @search="fetchPracticeAreaData"
              >
              </a-select>
            </a-form-item>
            <a-select
              v-model:value="modelRef.subPracticeAreas"
              class="select-input"
              mode="tags"
              placeholder="Search and Select Sub Practice Area"
              style="margin-bottom: 0.5rem"
              :filter-option="false"
              :options="sub_practice_area_tags_options"
              @search="fetchSubPracticeAreaData"
            >
            </a-select>
            <a-select
              v-model:value="modelRef.functions"
              class="select-input"
              mode="tags"
              placeholder="Search and  Select Function"
              style="margin-bottom: 0.5rem"
              :filter-option="false"
              :options="functionOptions"
              @search="fetchFunctionData"
            >
            </a-select>
            <a-select
              v-model:value="modelRef.subFunctions"
              class="select-input"
              mode="tags"
              :options="subfunctionOptions"
              placeholder="Search and Select Sub-Function"
              style="margin-bottom: 0.5rem"
              :filter-option="false"
              @search="fetchSubFunction"
            >
            </a-select>
            <a-select
              v-model:value="modelRef.subSubFunctions"
              class="select-input"
              mode="tags"
              :options="subsubfunctionOptions"
              placeholder="Search and Select Sub-Sub Function"
              style="margin-bottom: 0.5rem"
              :filter-option="false"
              @search="fetchSubSubFunction"
            >
            </a-select>
            <a-select
              v-model:value="modelRef.products"
              class="select-input"
              mode="tags"
              :options="productsOptions"
              placeholder="Search and Select Product"
              style="margin-bottom: 0.5rem"
              :filter-option="false"
              @search="getproducts"
            >
            </a-select>
            <a-select
              v-model:value="modelRef.segments"
              class="select-input"
              mode="tags"
              placeholder="Search and Select Segment"
              style="margin-bottom: 0.5rem"
              :filter-option="false"
              :options="segmentOptions"
              @search="fetchSegmentsData"
            >
            </a-select>
            <a-select
              style="margin-bottom: 0.5rem"
              v-model:value="modelRef.regions"
              class="select-input"
              mode="tags"
              placeholder="Search and Select Region"
              :filter-option="false"
              :options="regionTagsOptions"
              @search="fetchRegionData"
            >
            </a-select>
            <a-select
              v-model:value="modelRef.sectors"
              class="select-input"
              mode="tags"
              placeholder="Search and Select Sector"
              style="margin-bottom: 0.5rem"
              :filter-option="false"
              :options="sector_tags_options"
              @search="fetchSectorData"
            >
            </a-select>
          </div>
        </div>
        <div class="create-candidate-add-tags">
          <div class="title">Tags</div>
          <div class="form-sub-section">
            <div class="add-candidate-form-elements">
              <p class="input-label">Tags</p>
              <a-input
                class="form-input"
                placeholder="Add Tags"
                style="margin-bottom: 1.5rem"
                v-model:value="modelRef.tagListsValue"
                @keyup.enter="addTags"
              ></a-input>
            </div>
          </div>
          <div class="tag-lists">
            <a-tag
              v-for="tag in modelRef.tags"
              :key="tag"
              class="tags"
              style="
                padding: 10px;
                border-radius: 15px;
                font-family: 'PoppinsSemiBold';
                margin-bottom: 20px;
              "
              closable
              @close="handleTagsClose(tag)"
              color="#E5816A"
            >
              {{ tag }}
            </a-tag>
          </div>
        </div>
        <div
          class="create-candidate-add-documents"
          style="min-height: 20rem !important"
        >
          <div class="title">Documents</div>
          <div class="upload-component">
            <a-upload-dragger
              :multiple="true"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              name="file"
              @change="handleChange"
              style="border-radius: 15px; border-color: rgba(229, 129, 106, 1)"
            >
              <p class="ant-upload-drag-icon">
                <CloudUploadOutlined style="color: rgba(229, 129, 106, 1)" />
              </p>
              <p class="ant-upload-text">
                Drag & drop or Click on this area to upload file
              </p>
              <p class="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files
              </p>
            </a-upload-dragger>
          </div>
        </div>
        <div class="create-candidate-add-comment">
          <div class="title">Vito Comments</div>
          <div class="add-comments-section">
            <div style="display: inline-block; margin-bottom: 5rem">
              <a-avatar :size="30">
                <template #icon><UserOutlined /></template>
              </a-avatar>
            </div>
            <a-textarea
              v-model:value="modelRef.comment"
              :rows="5"
              placeholder="Autosize height based on content lines"
            />
          </div>
        </div>
        <div class="create-candidate-add-meeting-details">
          <div class="title">Meeting History</div>
          <div class="form-sub-section">
            <div class="add-candidate-form-elements left-form-elements">
              <a-form-item>
                <span class="input-label">Date</span>
                <a-date-picker
                  class="date-input"
                  style="
                    width: 96%;
                    border-radius: 15px;
                    height: 2.75rem;
                    margin-top: 12px;
                  "
                  v-model:value="meetings.date"
                >
                </a-date-picker>
              </a-form-item>
            </div>
            <div class="add-candidate-form-elements">
              <a-form-item>
                <span class="input-label">Level</span>
                <a-select
                  labelInValue
                  class="select-input"
                  v-model:value="meetings.levelId"
                  placeholder="Enter"
                  :options="levelOptions"
                ></a-select>
              </a-form-item>
            </div>
          </div>
          <div class="form-sub-section">
            <div class="add-candidate-form-elements left-form-elements">
              <a-form-item>
                <span class="input-label">Meeting Type</span>
                <span
                  ><a-rate
                    v-model:value="meetings.rating"
                    count="4"
                    allow-half
                    style="
                      margin-left: 1rem;
                      font-size: 14px;
                      padding-bottom: 0.15rem;
                      color: #6fcf97;
                    "
                /></span>
                <a-select
                  labelInValue
                  :options="meeting_type_options"
                  class="select-input"
                  v-model:value="meetings.meetingType"
                >
                </a-select>
              </a-form-item>
            </div>
            <div class="add-candidate-form-elements">
              <a-form-item>
                <span class="input-label">Meeting By</span>
                <a-select
                  labelInValue
                  class="select-input"
                  :options="users_options"
                  v-model:value="meetings.meetingBy"
                >
                </a-select>
              </a-form-item>
            </div>
          </div>
          <div class="form-sub-section">
            <div class="add-candidate-form-elements left-form-elements">
              <span class="input-label">Team</span>
              <a-select
                show-search
                labelInValue
                class="select-input"
                v-model:value="meetings.teamId"
                @change="getSubTeam(meetings.teamId.value)"
                :filter-option="false"
                :options="teams_options"
                @search="getTeams"
              >
              </a-select>
            </div>
            <div class="add-candidate-form-elements">
              <a-form-item>
                <span class="input-label">Sub Team</span>
                <a-select
                  labelInValue
                  class="select-input"
                  v-model:value="meetings.subTeamId"
                  :options="sub_teams_options"
                >
                </a-select>
              </a-form-item>
            </div>
          </div>
          <div class="add-comments-section" style="margin-top: 20px">
            <div class="meeting-comment-section">
              <div>
                <a-avatar :size="30">
                  <template #icon><UserOutlined /></template>
                </a-avatar>
              </div>
              <a-textarea
                v-model:value="meetings.remarks"
                :rows="5"
                placeholder="Autosize height with minimum and maximum number of lines"
              />
            </div>
          </div>
          <div class="meeting-history-btn">
            <a-button type="text" @click="meetingHistoryRmoveData"
              >Clear</a-button
            >
            <a-button
              style="background: #e5816a; padding: 0 1.5rem; color: white"
              @click="meetingHistorySubmit"
              >Save</a-button
            >
          </div>
          <div class="added-details-list" style="margin-top: 20px">
            <a-table
              :columns="meetingHistoryColumns"
              :data-source="meetings.meetingHistoryDataList"
              :pagination="false"
            >
              <template #meetingType="{ text }">
                {{ text ? text : "-" }}
              </template>
              <template #meetingBy="{ text }">
                {{ text ? text : "-" }}
              </template>
              <template #teamId="{ text }">
                {{ text ? text : "-" }}
              </template>
              <template #subTeamId="{ text }">
                {{ text ? text : "-" }}
              </template>
              <template #remarks="{ text }">
                {{ text ? "1" : "0" }}
              </template>

              <template #rating="{ text }">
                <a-rate
                  disabled
                  count="4"
                  allow-half
                  :value="text"
                  style="
                    margin-left: 1rem;
                    font-size: 14px;
                    padding-bottom: 0.15rem;
                    color: #6fcf97;
                  "
                />
              </template>
              <template #meetingHistoryDelete="{ record }">
                <a-popconfirm
                  title="Are you sure delete you want to delete?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="confirmMeetingDelete(record.key)"
                  @cancel="cancel"
                >
                  <DeleteOutlined style="cursor: pointer" />
                </a-popconfirm>
              </template>
            </a-table>
          </div>
        </div>
        <div class="create-candidate-add-background">
          <div class="title">Background</div>
          <div class="text-editor-section">
            <QuillEditor
              v-model:content="backgroundContent"
              @update:content="onEditorChange($event)"
            />
          </div>
        </div>
        <div class="create-candidate-add-aum">
          <div class="title">AUM</div>
          <div class="text-editor-section">
            <QuillEditor
              v-model:content="aumContent"
              @update:content="onAumEditorChange($event)"
            />
          </div>
        </div>
        <a-button class="create-candidate-btn" @click="onSubmit"
          >Create Candidate</a-button
        >
      </div>
    </a-form>
  </div>
</template>

<script>
import {
  PlusOutlined,
  MinusOutlined,
  CloudUploadOutlined,
  // CheckOutlined,
  // CloseOutlined,
  DeleteOutlined,
  UserOutlined,
} from "@ant-design/icons-vue";
import {
  defineComponent,
  reactive,
  toRaw,
  computed,
  onMounted,
  ref,
  toRefs,
  watch,
} from "vue";
import { Form } from "ant-design-vue";
import { message } from "ant-design-vue";
import { QuillEditor, Delta } from "@vueup/vue-quill";
import Services from "../../../services/apis";
import { useRouter } from "vue-router";
import Utils from "../../../utils";

const workExperienceTableColumns = [
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    align: "center",
    slots: { customRender: "company" },
    width: 150,
  },
  {
    title: "Designation",
    dataIndex: "designation",
    key: "designation",
    align: "center",
    slots: { customRender: "designation" },
    width: 150,
  },
  {
    title: "Reporting To",
    dataIndex: "reportingTo",
    key: "reportingTo",
    align: "right",
    slots: { customRender: "reportingTo" },
    width: 150,
  },
  {
    title: "From Date",
    dataIndex: "from",
    key: "from",
    align: "right",
    slots: { customRender: "from" },
    width: 150,
  },
  {
    title: "To Dtae",
    dataIndex: "to",
    key: "to",
    align: "right",
    slots: { customRender: "to" },
    width: 150,
  },
  {
    title: "CTC",
    dataIndex: "ctc",
    key: "ctc",
    align: "right",
    slots: { customRender: "ctc" },
    width: 150,
  },
  {
    title: "",
    dataIndex: "delete",
    key: "delete",
    align: "right",
    slots: { customRender: "delete" },
    width: 150,
  },
];
const educationDetailsColumns = [
  {
    title: "Degree",
    dataIndex: "degreeId",
    key: "degreeId",
    align: "center",
    slots: { customRender: "degreeId" },
    width: 150,
  },
  {
    title: "University",
    dataIndex: "instituteId",
    key: "instituteId",
    align: "right",
    slots: { customRender: "instituteId" },
    width: 150,
  },
  {
    title: "Level",
    dataIndex: "levelId",
    key: "levelId",
    align: "right",
    slots: { customRender: "levelId" },
    width: 150,
  },
  {
    title: "Speciality",
    dataIndex: "speciality",
    key: "speciality",
    align: "right",
    slots: { customRender: "speciality" },
    width: 150,
  },
  {
    title: "Year",
    dataIndex: "year",
    key: "year",
    align: "right",
    slots: { customRender: "year" },
    width: 150,
  },
  {
    title: "",
    dataIndex: "educationDelete",
    key: "educationDelete",
    align: "right",
    slots: { customRender: "educationDelete" },
    width: 150,
  },
];

const meetingHistoryColumns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    align: "center",
  },
  {
    title: "Level",
    dataIndex: "levelId",
    key: "levelId",
    align: "center",
    width: 150,
  },
  {
    title: "Meeting Type",
    dataIndex: "meetingType",
    key: "meetingType",
    align: "center",
    slots: { customRender: "meetingType" },
  },
  {
    title: "Meeting By",
    dataIndex: "meetingBy",
    key: "meetingBy",
    align: "center",
    slots: { customRender: "meetingBy" },
  },
  {
    title: "Team",
    dataIndex: "teamId",
    key: "teamId",
    align: "center",
    slots: { customRender: "teamId" },
  },
  {
    title: "Sub Team",
    dataIndex: "subTeamId",
    key: "subTeamId",
    align: "center",
    slots: { customRender: "subTeamId" },
  },
  {
    title: "Comments",
    dataIndex: "remarks",
    key: "remarks",
    align: "center",
    slots: { customRender: "remarks" },
  },
  {
    title: "Rating",
    dataIndex: "rating",
    key: "subTeamId",
    slots: { customRender: "rating" },
    align: "center",
    width: 150,
  },
  {
    title: "",
    dataIndex: "meetingHistoryDelete",
    key: "meetingHistoryDelete",
    align: "right",
    slots: { customRender: "meetingHistoryDelete" },
    width: 100,
  },
];

const tagLists = [
  "Banking and Financial Services",
  "Retail Finance",
  "Secured Assets",
  "Operations",
];
const selectedItems = ref(["Apples"]);
const OPTIONS = [
  "Banking and Financial Services",
  "Retail Finance",
  "Secured Assests",
  "Unsecured Assests",
  "Operations",
];

const tagTitles = [
  "Verticals",
  "Practice Area",
  "Sub Practice Area",
  "Function",
  "Sub-Function",
  "Sub-SubFunction",
  "Product",
  "Segment",
  "Region",
  "Sector",
];

export default {
  components: {
    PlusOutlined,
    MinusOutlined,
    CloudUploadOutlined,
    // CheckOutlined,
    // CloseOutlined,
    QuillEditor,
    DeleteOutlined,
    UserOutlined,
  },
  setup() {
    // All selects options
    let meeting_type_options = ref([]);
    let education_speciality_options = ref([]);
    let practice_area_options = ref([]);
    let vertical_tags_options = ref([]);
    let practice_area_tags_options = ref([]);
    let sub_practice_area_tags_options = ref([]);
    let functionOptions = ref([]);
    let subfunctionOptions = ref([]);
    let subsubfunctionOptions = ref([]);
    let productsOptions = ref([]);
    let segmentOptions = ref([]);
    let regionTagsOptions = ref([]);
    let sector_tags_options = ref([]);
    let designation_options = ref([]);
    let resume_type_options = ref("");
    let education_degrees_options = ref([]);
    let university_options = ref([]);
    let education_levels_options = ref([]);
    let users_options = ref([]);
    let teams_options = ref([]);
    let levelOptions = ref([]);
    let sub_teams_options = ref([]);
    let genderOptions = ref();
    const router = useRouter();
    let experiences = reactive({
      company: "",
      from: "",
      to: "",
      designation: "",
      ctc: "",
      reportingTo: "",
      workdetailsDataList: [],
    });
    let qualifications = reactive({
      degreeId: ref(undefined),
      instituteId: undefined,
      levelId: undefined,
      speciality: undefined,
      year: "",
      educationDetailsDataList: [],
      educationDetailsId: [],
    });

    let meetings = reactive({
      date: "",
      levelId: "",
      meetingType: "",
      meetingBy: "",
      teamId: "",
      subTeamId: "",
      remarks: "",
      rating: 0,
      meetingHistoryDataList: [],
      meetingHistoryId: [],
    });
    onMounted(() => {
      fetchVerticalData();
      fetchSubPracticeAreaData();
      fetchFunctionData();
      fetchSubFunction();
      fetchSubSubFunction();
      fetchPracticeAreaData();
      fetchRegionData();
      fetchSegmentsData();
      getEducationDegree();
      getEducationLevel();
      getEducationinstitute();
      getproducts();
      fetchSectorData();
      getGenders();
      getMeetingType();
      getMeetingBy();
      getTeams();
      getSubTeam();
      getMeetingLevel();
      Services.genderListing({ types: ["EDUCATIONAL_SPECIALISATION"] })
        .then((response) => {
          const { data } = response;
          education_speciality_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});

      Services.resumeListing({ types: ["RESUME"] })
        .then((response) => {
          const { data } = response;
          resume_type_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    });

    const getEducationDegree = (value) => {
      let payload = {};
      payload = {
        q: value,
        types: ["DEGREE"],
        mode: "AUTOCOMPLETE",
      };
      Services.eduDegreesListing(payload)
        .then((response) => {
          const { data } = response;
          education_degrees_options.value = data[0].data.map(
            ({ id: value, name: label }) => ({
              value,
              label,
            })
          );
        })

        .catch((e) => {});
    };

    const getEducationLevel = () => {
      Services.getlevelDetail({
        q: "",
        types: ["EDUCATION"],
      })
        .then((response) => {
          const { data } = response;
          education_levels_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };
    const getEducationinstitute = (value) => {
      let payload = {};
      payload = {
        q: value,
        types: ["INSTITUTE"],
        mode: "AUTOCOMPLETE",
      };
      Services.eduInstituteListing(payload)
        .then((response) => {
          const { data } = response;
          university_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const fetchVerticalData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getVerticalMasterDetail(payload)
        .then((response) => {
          const { data } = response;
          vertical_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const fetchPracticeAreaData = (value) => {
      let payload = {};
      payload = { q: value, types: ["PRACTICE_AREA"] };
      Services.getPracticeAreaDetail(payload)
        .then((response) => {
          const { data } = response;

          practice_area_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };
    const fetchSubPracticeAreaData = (value) => {
      let payload = {};
      payload = { q: value, types: ["SUB_PRACTICE_AREA"] };
      Services.getPracticeAreaDetail(payload)
        .then((response) => {
          const { data } = response;

          sub_practice_area_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const fetchRegionData = (value) => {
      let payload = {};
      payload = { q: value, types: ["REGION"] };
      Services.genderListing(payload)
        .then((response) => {
          const { data } = response;

          regionTagsOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };
    const fetchSegmentsData = (value) => {
      let payload = { q: value };
      Services.getSegmentDetail(payload)
        .then((response) => {
          const { data } = response;

          segmentOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const fetchFunctionData = (value) => {
      let payload = { q: value, types: ["FUNCTION"] };
      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          functionOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const fetchSubFunction = (value) => {
      let payload = { q: value, types: ["SUB_FUNCTION"] };

      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;

          subfunctionOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };
    const fetchSubSubFunction = (value) => {
      let payload = { q: value, types: ["SUB_SUB_FUNCTION"] };

      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;

          subsubfunctionOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const getMeetingType = () => {
      Services.genderListing({ types: ["MEETING_TYPES"] })
        .then((response) => {
          const { data } = response;
          meeting_type_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };
    const getMeetingBy = () => {
      let user_listing_payload = {
        q: "",
        pageNo: 0,
        mode: "AUTOCOMPLETE",
        type: "MEMBER",
      };

      Services.userListing(user_listing_payload)
        .then((response) => {
          const { data } = response;
          users_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };
    const getSubTeam = (teamId) => {
      Services.teamListing({
        types: ["SUB_TEAM"],
        mode: "AUTOCOMPLETE",
        teams: teamId,
      })
        .then((response) => {
          const { data } = response;
          sub_teams_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const getTeams = (value) => {
      let payload = {};
      payload = {
        q: value,
        types: ["TEAM"],
        mode: "AUTOCOMPLETE",
      };
      Services.teamListing(payload)
        .then((response) => {
          const { data } = response;
          teams_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const getMeetingLevel = () => {
      Services.getlevelDetail({
        q: "",
        types: ["DESIGNATION"],
      })
        .then((response) => {
          const { data } = response;
          levelOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };
    const workdatasubmit = () => {
      let company = experiences.company;
      let designation = experiences.designation;
      let reportingTo = experiences.reportingTo;
      let from = experiences.from;
      let to = experiences.to;
      let ctc = experiences.ctc;
      let workdetailsDataList = experiences.workdetailsDataList;
      let id = workdetailsDataList.length + 1;
      let workDetails = {
        key: id,
        company: company,
        designation: designation,
        reportingTo: reportingTo,
        from: from ? Utils.dispalyDateMonthYear(from) : "",
        to: to ? Utils.dispalyDateMonthYear(to) : "",
        ctc: ctc,
      };

      company && from !== ""
        ? workdetailsDataList.push(workDetails)
        : (workdetailsDataList = []);
    };
    const educationdatasubmit = () => {
      let educationDetailsDataList = qualifications.educationDetailsDataList;
      let id = educationDetailsDataList.length + 1;
      let educationDetails = {
        key: id,
        degreeId: qualifications.degreeId.label,
        instituteId: qualifications.instituteId.label,
        levelId: qualifications.levelId.label,
        speciality: qualifications.speciality.label,
        year: qualifications.year,
      };
      let educationDetailsId = {
        key: id,
        degreeId: qualifications.degreeId.value,
        instituteId: qualifications.instituteId.value,
        levelId: qualifications.levelId.value,
        speciality: qualifications.speciality.value,
        year: qualifications.year,
      };

      if (
        educationDetails.degreeId &&
        educationDetails.instituteId &&
        educationDetails.levelId &&
        educationDetails.speciality &&
        educationDetails.year !== ""
      ) {
        educationDetailsDataList.push(educationDetails);
        qualifications.educationDetailsId.push(educationDetailsId);
      } else {
        educationDetailsDataList = [];
      }
    };

    const meetingHistorySubmit = () => {
      let meetingHistoryDataList = meetings.meetingHistoryDataList;
      let id = meetingHistoryDataList.length + 1;
      let meetingHistoryDetails = {
        key: id,
        date: Utils.dispalyDateMonthYear(meetings.date),
        levelId: meetings.levelId.label,
        meetingType: meetings.meetingType.label,
        meetingBy: meetings.meetingBy.label,
        teamId: meetings.teamId.label,
        subTeamId: meetings.subTeamId.label,
        remarks: meetings.remarks,
        rating: meetings.rating,
      };
      let meetingHistoryId = {
        key: id,
        date: Utils.dispalyDateMonthYear(meetings.date)
          ? Utils.dispalyDateMonthYear(meetings.date)
          : "",
        levelId: meetings.levelId.value ? meetings.levelId.value : "",
        meetingType: meetings.meetingType.value
          ? meetings.meetingType.value
          : "",
        meetingBy: meetings.meetingBy.value ? meetings.meetingBy.value : "",
        teamId: meetings.teamId.value ? meetings.teamId.value : "",
        subTeamId: meetings.subTeamId.value ? meetings.subTeamId.value : "",
        remarks: meetings.remarks,
        rating: meetings.rating * 2,
      };

      if (meetingHistoryDetails.date && meetingHistoryDetails.levelId !== "") {
        meetingHistoryDataList.push(meetingHistoryDetails);
        meetings.meetingHistoryId.push(meetingHistoryId);
      } else {
        meetingHistoryDataList = [];
      }
    };
    const workremoveData = () => {
      Object.assign(experiences, {
        company: "",
        designation: "",
        reportingTo: "",
        from: "",
        to: "",
        ctc: "",
      });
    };
    const educationremoveData = () => {
      Object.assign(qualifications, {
        degreeId: "",
        instituteId: "",
        levelId: "",
        speciality: "",
        year: "",
      });
    };
    const meetingHistoryRmoveData = () => {
      Object.assign(meetings, {
        date: "",
        levelId: "",
        meetingType: "",
        meetingBy: "",
        teamId: "",
        subTeamId: "",
        remarks: 0,
        rating: 0,
      });
    };

    const backgroundContent = ref < Delta > new Delta([]);
    const aumContent = ref < Delta > new Delta([]);
    const formRef = ref();
    // Document Upload handle functions
    const handleChange = (info) => {
      const status = info.file.status;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        let filesLists = info.fileList;
        for (let i = 0; i < filesLists; i++) {
          modelRef.documents.push(filesLists[i].name);
        }
        console.log(info.fileList);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    };

    // to add tags to tagList
    const addTags = () => {
      const tagListsValue = modelRef.tagListsValue;
      let tags = modelRef.tags;

      if (tagListsValue && tags.indexOf(tagListsValue) === -1) {
        tags = [...tags, tagListsValue];
      }

      Object.assign(modelRef, {
        tags,
        inputVisible: false,
        tagListsValue: "",
      });
    };

    const handleTagsClose = (removedTag) => {
      const newTagList = modelRef.tags.filter((tag) => tag !== removedTag);
      modelRef.tags = newTagList;
    };

    const useForm = Form.useForm;

    let modelRef = reactive({
      firstName: "",
      lastName: "",
      resumeType: undefined,
      resumeId: "",
      mobile: "",
      email: "",
      socialAccounts: {
        linkedIn: {
          link: "",
        },
        facebook: {
          link: "",
        },
        twitter: {
          link: "",
        },
        skype: {
          link: "",
        },
      },

      gender: "",
      experiences: [
        {
          company: "",
          from: "",
          to: "",
          designation: "",
          ctc: "",
          reportingTo: "",
        },
      ],
      qualifications: [
        {
          levelId: "",
          degreeId: "",
          instituteId: "",
          speciality: "",
          year: 0,
        },
      ],
      verticals: [],
      practiceAreas: [],
      subPracticeAreas: [],
      functions: [],
      subFunctions: [],
      subSubFunctions: [],
      products: [],
      segments: [],
      regions: [],
      sectors: [],
      comment: "",
      meetings: [
        {
          date: "",
          levelId: "",
          meetingType: "",
          meetingBy: "",
          teamId: "",
          subTeamId: "",
          remarks: "",
          rating: 0,
        },
      ],
      tags: [],
      documents: [],
      inputVisible: false,
      tagListsValue: "",
      background: {
        text: "",
        editorContent: "",
      },
      aum: {
        text: "",
        editorContent: "",
      },
      address: {
        type: "PERMANENT",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
      cics: [],
      industries: [],
    });

    const getGenders = () => {
      let payload = {
        q: "",
        pageNo: 0,
        types: ["GENDER"],
      };
      Services.genderListing(payload)
        .then((response) => {
          const { data } = response;
          genderOptions.value = data[0].data.map(
            ({ name: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const getproducts = (value) => {
      let payload = {
        q: value,
      };
      Services.getProductMasterDetail(payload)
        .then((response) => {
          const { data } = response;
          productsOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const fetchSectorData = (value) => {
      let payload = {};
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getSectorDetail(payload)
        .then((response) => {
          const { data } = response;
          sector_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const rulesRef = reactive({
      firstName: [
        {
          required: true,
          message: "Please enter first name",
        },
      ],
      lastName: [
        {
          required: true,
          message: "Please enter last name",
        },
      ],
      resumeType: [
        {
          required: true,
          message: "Please select resume type",
        },
      ],
      resumeId: [
        {
          required: true,
          message: "Please enter resume id",
        },
      ],
      verticals: [
        {
          required: true,
          message: "Please select verticals",
        },
      ],
      practiceAreas: [
        {
          required: true,
          message: "Please select practice area",
        },
      ],
      email: [
        {
          required: false,
          type: "email",
          message: "Please enter valid email",
        },
      ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    );

    const confirmDelete = (key) => {
      experiences.workdetailsDataList = experiences.workdetailsDataList.filter(
        (item) => item.key !== key
      );
    };

    const confirmEducationDelete = (key) => {
      qualifications.educationDetailsDataList =
        qualifications.educationDetailsDataList.filter(
          (item) => item.key !== key
        );
      qualifications.educationDetailsId =
        qualifications.educationDetailsId.filter((item) => item.key !== key);
    };

    const confirmMeetingDelete = (key) => {
      meetings.meetingHistoryDataList = meetings.meetingHistoryDataList.filter(
        (item) => item.key !== key
      );
      meetings.meetingHistoryId = meetings.meetingHistoryId.filter(
        (item) => item.key !== key
      );
    };

    const onEditorChange = (event) => {
      modelRef.background.editorContent = JSON.stringify(event.ops);
      let tempArray = event.ops.map((x) => x.insert);
      modelRef.background.text = tempArray.join(" ");
    };

    const onAumEditorChange = (event) => {
      modelRef.aum.editorContent = JSON.stringify(event.ops);
      let tempArray = event.ops.map((x) => x.insert);
      modelRef.aum.text = tempArray.join(" ");
    };
    const onSubmit = () => {
      validate()
        .then((result) => {
          delete modelRef["inputVisible"];
          delete modelRef["tagListsValue"];

          if (modelRef.experiences.length) {
            const experienceArray = experiences.workdetailsDataList.map(
              ({ key, ...rest }) => rest
            );
            Object.assign(experiences.workdetailsDataList, experienceArray);
          }

          if (modelRef.qualifications.length) {
            const qualificationArray = qualifications.educationDetailsId.map(
              ({ key, ...rest }) => rest
            );
            Object.assign(
              qualifications.educationDetailsId,
              qualificationArray
            );
          }

          if (modelRef.meetings.length) {
            const meetingArray = meetings.meetingHistoryId.map(
              ({ key, ...rest }) => rest
            );
            Object.assign(meetings.meetingHistoryId, meetingArray);
          }
          if (modelRef.email === "") {
            delete modelRef.email;
          }
          modelRef.qualifications.year = parseInt(modelRef.qualifications.year);
          modelRef.gender = modelRef.gender.toUpperCase();
          modelRef.experiences = experiences.workdetailsDataList;
          modelRef.qualifications = qualifications.educationDetailsId;
          modelRef.meetings = meetings.meetingHistoryId;
          Services.candidateCreate(modelRef).then((response) => {
            message.success("Candidate Created Successfully");
            router.push("/candidates");
          });
        })
        .catch((err) => {});
    };

    return {
      onSubmit,
      selectedItems,
      workExperienceTableColumns,
      handleChange,
      addTags,
      fetchRegionData,
      fetchFunctionData,
      fetchSegmentsData,
      segmentOptions,
      workremoveData,
      regionTagsOptions,
      fetchSubPracticeAreaData,
      functionOptions,
      subfunctionOptions,
      subsubfunctionOptions,
      productsOptions,
      educationdatasubmit,
      educationDetailsColumns,
      educationremoveData,
      formRef,
      workdatasubmit,
      handleTagsClose,
      experiences,
      fileList: ref([]),
      verticals: ref([]),
      practiceAreas: ref([]),
      subPracticeAreas: ref([]),
      functions: ref([]),
      subFunctions: ref([]),
      subSubFunctions: ref([]),
      sector_tags_options,
      segments: ref([]),
      regions: ref([]),
      sectors: ref([]),
      meeting_type_options,
      education_speciality_options,
      qualifications,
      vertical_tags_options,
      designation_options,
      practice_area_options,
      practice_area_tags_options,
      sub_practice_area_tags_options,
      resume_type_options,
      education_degrees_options,
      university_options,
      education_levels_options,
      users_options,
      teams_options,
      levelOptions,
      sub_teams_options,
      fetchVerticalData,
      fetchPracticeAreaData,
      modelRef,
      validate,
      validateInfos,
      resetFields,
      getGenders,
      genderOptions,
      confirmDelete,
      getEducationDegree,
      confirmEducationDelete,
      getEducationLevel,
      getEducationinstitute,
      getproducts,
      fetchSubFunction,
      fetchSubSubFunction,
      fetchSectorData,
      comment: ref(""),
      getMeetingType,
      getMeetingBy,
      meetings,
      meetingHistoryColumns,
      meetingHistorySubmit,
      confirmMeetingDelete,
      meetingHistoryRmoveData,
      getSubTeam,
      getTeams,
      getMeetingLevel,
      onEditorChange,
      backgroundContent,
      aumContent,
      onAumEditorChange,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/addCandidate.scss";
</style>
